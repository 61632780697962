import { CryptoCurrencyID } from "@/shared/constants";

export enum Blockchain {
  ETHEREUM = "ETHEREUM",
  POLYGON = "POLYGON",
  ARBITRUM = "ARBITRUM",
}

interface PaymentMethodConfig {
  id: CryptoCurrencyID;
  label: string;
  tokenAddress: string;
  fee: number;
  decimals: number;
  activity: boolean;
}

interface ChainContractsConfig {
  nftFactoryAddress: string;
  exchangeAddress: string;
  royaltiesProviderAddress: string;
  paymentManagerAddress: string;
  artOrderAddress: string;
}

interface ChainConfig {
  id: number;
  name: Blockchain;
  label: string;
  etherscanUrl: string;
  alchemyKey: string;
  rpcUrl: string;
  contracts: ChainContractsConfig;
  legacyCollection: string;
  endemicCollection: string;
  paymentMethods: {
    [address: string]: PaymentMethodConfig;
  };
}

export type { PaymentMethodConfig, ChainContractsConfig, ChainConfig };
